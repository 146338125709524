import Phaser                  from 'phaser';
import KaichouHeadpatSimulator from './KaichouHeadpatSimulator';
import PlayScene               from './PlayScene';
import EndScene                from './EndScene';
import GLOBAL_CONFIG           from './globalConfig';

const DEBUG = false;

const config = {
  type:            Phaser.AUTO,
  width:           GLOBAL_CONFIG.VIEWPORT_WIDTH,
  height:          GLOBAL_CONFIG.VIEWPORT_HEIGHT,
  autoCenter:      false,
  backgroundColor: 0xffffff,
  physics: {
    default: 'arcade',
    arcade: {
      gravity: { y: GLOBAL_CONFIG.WORLD_GRAVITY },
      debug:   DEBUG,
    },
  },
  scale: {
      mode: Phaser.Scale.FIT,
      parent:     'game-container',
      autoCenter: Phaser.Scale.CENTER_BOTH,
      width: GLOBAL_CONFIG.VIEWPORT_WIDTH,
      height: GLOBAL_CONFIG.VIEWPORT_HEIGHT
  },
  scene: [PlayScene, EndScene],
}

new KaichouHeadpatSimulator(config);
