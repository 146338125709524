import GLOBAL_CONFIG from './globalConfig';

class EndScene extends Phaser.Scene {
    constructor () {
      super({key: 'EndScene'});
    }

    preload() {
        this.load.image('true-gachi', './assets/img/kaichou-true-gachi.png');
    }

    create() {
        this.cameras.main.fadeIn(100, 255, 255, 255);
        this.trueGachi = this.add.image(0, 0, 'true-gachi').setOrigin(0, 0);
        this.trueGachi.setX(this.trueGachi.width / 2);

    }

    update() {
    }
}

export default EndScene;