import GLOBAL_CONFIG from './globalConfig';

class PlayScene extends Phaser.Scene {
    constructor () {
        super({key: 'PlayScene'});
    }

    preload() {
        this.load.video('kaichou-pose-1', './assets/clips/kaichou-pose-1.mp4', 'canplaythrough', true);
        this.load.video('kaichou-pose-2', './assets/clips/kaichou-pose-2.mp4', 'canplaythrough', true);
        this.load.video('kaichou-pose-3', './assets/clips/kaichou-pose-3.mp4', 'canplaythrough', true);
        this.load.video('kaichou-pose-4', './assets/clips/kaichou-pose-4.mp4', 'canplaythrough', true);
        this.load.video('kaichou-horny', './assets/clips/kaichou-horny.mp4', 'canplaythrough', true);

        // Audio.
        this.load.audio('bgm', './assets/bgms/Cocktail_Glass.mp3');
        this.load.audio('marine-horny', './assets/sounds/marine-so-horny.mp3');
        this.load.audio('kaichou-oi', './assets/sounds/kaichou-oi.mp3');
        this.load.audio('kaichou-no-oppai', './assets/sounds/kaichou-oppai-sound.mp3');

        this.load.audio('kaichou-sound-1', './assets/sounds/kaichou-sound-1.mp3');
        this.load.audio('kaichou-sound-2', './assets/sounds/kaichou-sound-2.mp3');
        this.load.audio('kaichou-sound-3', './assets/sounds/kaichou-sound-3.mp3');
        this.load.audio('kaichou-sound-4', './assets/sounds/kaichou-sound-4.mp3');
        this.load.audio('kaichou-sound-5', './assets/sounds/kaichou-sound-5.mp3');
        this.load.audio('kaichou-sound-6', './assets/sounds/kaichou-sound-6.mp3');
        this.load.audio('kaichou-sound-7', './assets/sounds/kaichou-sound-7.mp3');

        // Images.
        this.load.image('hand', './assets/img/hand.png');
        this.load.image('random-senchou', './assets/img/random-senchou.png');
        this.load.image('level-2-gachi', './assets/img/level-2-gachi.png');
        this.load.image('level-3-gachi', './assets/img/level-3-gachi.png');
        this.load.image('level-4-gachi', './assets/img/level-4-gachi.png');
        this.load.image('level-5-gachi', './assets/img/level-5-gachi.png');

        this.load.image('heart', './assets/img/heart.png');

        this.loadText = this.add.text(10, 10, 'Loading: 0%', {
            fontSize:   '24px',
            fill:       '#000',
        });

        this.load.on('progress', value => {
            this.loadText.text = `Loading: ${Math.round(value * 100)}%`;
        });

        this.load.on('complete', () => {
            this.loadText.setVisible(false);
        });
    }

    create() {
        // this.cameras.main.setBackgroundColor('#1cff08');
        this.poses = [];

        const pose1 = this.add.video(0, 0, 'kaichou-pose-1').setOrigin(0, 0).setVisible(true);
        const pose2 = this.add.video(0, 0, 'kaichou-pose-2').setOrigin(0, 0).setVisible(false);
        const pose3 = this.add.video(0, 0, 'kaichou-pose-3').setOrigin(0, 0).setVisible(false);
        const pose4 = this.add.video(0, 0, 'kaichou-pose-4').setOrigin(0, 0).setVisible(false);

        this.kaichouHorny = this.add.video(0, 0, 'kaichou-horny').setOrigin(0, 0).setVisible(false);

        this.currentPose = 0;

        this.poses.push(pose1);
        this.poses.push(pose2);
        this.poses.push(pose3);
        this.poses.push(pose4);

        this.poses.forEach(pose => {
            pose.setDisplaySize(1280, 720);
        });

        this.kaichouHorny.setDisplaySize(1280, 720);

        this.oi = false;

        this.clicks = 0;

        this.hitAreas   = [];
        this.hornyAreas = [];

        const hitArea1   = this.add.rectangle(350, 720, 580, 580, 0x6666ff).setOrigin(0, 1);
        const hornyArea1 = this.add.rectangle(470, 720, 400, 50, 0x66ffff).setOrigin(0, 1);

        const hitArea2   = this.add.rectangle(320, 720, 540, 600, 0x6666ff).setOrigin(0, 1).setVisible(false);
        const hornyArea2 = this.add.rectangle(420, 720, 400, 80, 0x66ffff).setOrigin(0, 1).setVisible(false);

        const hitArea3   = this.add.rectangle(450, 720, 350, 600, 0x6666ff).setOrigin(0, 1).setVisible(false);
        const hornyArea3 = this.add.rectangle(500, 600, 300, 140, 0x66ffff).setOrigin(0, 1).setVisible(false);

        const hitArea4   = this.add.rectangle(540, 690, 140, 640, 0x6666ff).setOrigin(0, 1).setVisible(false);
        const hornyArea4 = this.add.rectangle(540, 260, 140, 60, 0x66ffff).setOrigin(0, 1).setVisible(false);

        this.gachiMeterBg = this.add.rectangle(20, 710, 50, 660).setOrigin(0, 1);
        this.gachiMeter   = this.add.rectangle(21, 709, 48, 658, 0xe13caa).setOrigin(0, 1);

        this.gachiMeter.setScale(1, 0);
        this.gachiMeterBg.setFillStyle(0x000000, .1);
        this.gachiMeterBg.setStrokeStyle(1, 0x000000, 1);

        this.hitAreas.push(hitArea1);
        this.hornyAreas.push(hornyArea1);

        this.hitAreas.push(hitArea2);
        this.hornyAreas.push(hornyArea2);

        this.hitAreas.push(hitArea3);
        this.hornyAreas.push(hornyArea3);

        this.hitAreas.push(hitArea4);
        this.hornyAreas.push(hornyArea4);

        this.hitAreas.forEach(hitArea => {
            hitArea.setInteractive();
            hitArea.setAlpha(.0000001);

            hitArea.on('pointerup', this.hitAreaClick, this);
        });

        this.hornyAreas.forEach(hornyArea => {
            hornyArea.setInteractive();
            hornyArea.setAlpha(.0000001);

            hornyArea.on('pointerup', this.hornyAreaClick, this);
        });

        // Audio.
        this.bgm            = this.sound.add('bgm');
        this.marineHorny    = this.sound.add('marine-horny');
        this.kaichouOi      = this.sound.add('kaichou-oi');
        this.kaichouNoOppai = this.sound.add('kaichou-no-oppai');

        this.bgm.setVolume(.08);
        this.bgm.setLoop(true);
        this.bgm.play();

        this.kaichouSounds = [];

        const sound1 = this.sound.add('kaichou-sound-1');
        const sound2 = this.sound.add('kaichou-sound-2');
        const sound3 = this.sound.add('kaichou-sound-3');
        const sound4 = this.sound.add('kaichou-sound-4');
        const sound5 = this.sound.add('kaichou-sound-5');
        const sound6 = this.sound.add('kaichou-sound-6');
        const sound7 = this.sound.add('kaichou-sound-7');

        this.kaichouSounds.push(sound1);
        this.kaichouSounds.push(sound2);
        this.kaichouSounds.push(sound3);
        this.kaichouSounds.push(sound4);
        this.kaichouSounds.push(sound5);
        this.kaichouSounds.push(sound6);
        this.kaichouSounds.push(sound7);

        this.soundTimer = null;
        this.currentlyPlaying = null;

        // Cursor.
        this.hand = this.add.sprite(0, 0, 'hand').setOrigin(1, 0.5);

        this.input.addListener('pointermove', (pointer) => {
            this.hand.x = pointer.x + 100;
            this.hand.y = pointer.y;
        });

        this.input.on('pointerup', () => {
            this.hand.setAngle(0);
        });

        this.input.on('pointerdown', () => {
            this.hand.setAngle(-15);
        });

        // Images.
        this.randomSenchou = this.add.image(1280, 720, 'random-senchou').setOrigin(1, 1);
        this.randomSenchou.setVisible(false);

        this.level2Gachi = this.add.image(-50, 545, 'level-2-gachi').setOrigin(0, .5);
        this.level3Gachi = this.add.image(-50, 380, 'level-3-gachi').setOrigin(0, .5);
        this.level4Gachi = this.add.image(-50, 215, 'level-4-gachi').setOrigin(0, .5);
        this.level4Gachi = this.add.image(-50, 48, 'level-5-gachi').setOrigin(0, .5);

        // Other vars.
        this.lastVoiceIndex = 0;
        this.alphaOutTween = null;
        this.alphaInTween = null;

        // Endless mode.
        this.endlessMode = false;

        this.endlessModeText = this.add.text(5, 5, 'Endless mode', {
            fontSize:   '12px',
            fill:       '#000',
        });

        this.endlessModeText.setVisible(false);

        this.hand.setDepth(20);

        this.level2Gachi.setInteractive();

        this.level2Gachi.on('pointerup', () => {
            this.endlessMode = !this.endlessMode;
            if (this.endlessMode) {
                this.endlessModeText.setVisible(true);
            } else {
                this.endlessModeText.setVisible(false);
            }
        }, this);
    }

    hitAreaClick() {
        if (this.oi) { return; }

        this.clicks += 1;

        const scale = (this.clicks / 1000);

        this.gachiMeter.setScale(1, scale);

        const heart = this.add.image(this.input.x + Phaser.Math.Between(-50, 50), this.input.y - 50, 'heart').setScale(Math.random() + .5);

        const duration = Phaser.Math.Between(500, 1000)

        this.add.tween({
            targets: heart,
            ease: 'Sine.easeIn',
            duration: duration,
            delay: 0,
            loop: 0,
            alpha: {
                getStart: () => 1,
                getEnd: () => 0
            },
        });

        this.add.tween({
            targets: heart,
            ease: 'Sine.easeIn',
            duration: duration,
            delay: 0,
            loop: 0,
            y: {
                getStart: () => heart.y,
                getEnd: () => heart.y - 100,
            },
            onComplete: () => {
                heart.destroy();
            }
        });

        if (this.clicks % 250 === 0) {
        // if (this.clicks % 5 === 0) {
            this.hitAreas[this.currentPose].setVisible(false);
            this.hornyAreas[this.currentPose].setVisible(false);

            this.currentPose += 1;

            if (this.poses.length === this.currentPose) {
                if (this.endlessMode) {
                    this.currentPose = 0;
                    this.clicks = 0;
                } else {
                    this.scene.start('EndScene');
                    return;
                }
            }

            this.hitAreas[this.currentPose].setVisible(true);
            this.hornyAreas[this.currentPose].setVisible(true);

            this.displayCurrentPose();
            if (this.currentlyPlaying) {
                this.currentlyPlaying.stop();
                this.currentlyPlaying = null;
            }
        }

        if (!this.currentlyPlaying) {
            const voiceStates = [
                [1, 2, 3, 4, 5, 6],
                [0, 2, 3, 4, 5, 6],
                [0, 1, 3, 4, 5, 6],
                [0, 1, 2, 4, 5, 6],
                [0, 1, 2, 3, 5, 6],
                [0, 1, 2, 3, 4, 6],
                [0, 1, 2, 3, 4, 5],
            ];

            const voiceIndex = voiceStates[this.lastVoiceIndex][Math.round(Math.random() * 5)];

            this.lastVoiceIndex = voiceIndex;

            this.currentlyPlaying = this.kaichouSounds[voiceIndex];
            this.currentlyPlaying.play();

            this.currentlyPlaying.on('complete', () => {
                this.currentlyPlaying = null;
            });
        }

        if (this.poses[this.currentPose].isPlaying()) { return; }

        this.poses[this.currentPose].play();

        this.poses[this.currentPose].on('complete', () => {
            if (this.currentlyPlaying) {
                this.currentlyPlaying.stop();
                this.currentlyPlaying = null;
            }
        });
    }

    hornyAreaClick() {
        if (this.oi) { return; }

        this.bgm.pause();

        if (this.currentlyPlaying) {
            this.currentlyPlaying.stop();
            this.currentlyPlaying = null;
        }

        this.cameras.main.fadeIn(100, 255, 255, 255);

        this.oi = true;

        this.hand.setVisible(false);

        this.poses[this.currentPose].setPaused(true);
        this.poses[this.currentPose].setVisible(false);

        this.kaichouHorny.setVisible(true);
        this.kaichouHorny.play();

        this.kaichouNoOppai.play();

        this.time.addEvent({
            delay: 2000,
            callback: () => {
                this.marineHorny.play();
                this.randomSenchou.setVisible(true);
            },
            callbackScope: this,
            loop: false
        });

        this.time.addEvent({
            delay: 10000,
            callback: () => {
              this.kaichouOi.play();
            },
            callbackScope: this,
            loop: false
        });

        this.kaichouHorny.on('complete', () => {
            this.cameras.main.fadeIn(100, 255, 255, 255);
            this.oi = false;

            this.kaichouOi.stop();
            this.kaichouHorny.setVisible(false);
            this.randomSenchou.setVisible(false);

            this.displayCurrentPose();
            this.bgm.play();

            this.hand.setVisible(true);
        }, this);
    }

    displayCurrentPose() {
        this.poses.forEach((pose, index) => {
            pose.stop();
            pose.seekTo(0);

            if (index === this.currentPose) {
                this.cameras.main.fadeIn(100, 255, 255, 255);
                pose.setVisible(true);
            } else {
                pose.setVisible(false);
            }
        })
    }

    update() {
    }
}

export default PlayScene;